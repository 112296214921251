import axios from 'axios';
import load from './load';
import nav from './nav';
const body = document.body;

function updateHistory(url) {
  history.pushState(url, null, url);
}

function updateBodyModifier(newBodyClass) {
  const bodyClasses = Array.from(body.classList);
  bodyClasses.forEach(classString => {
    if (classString.startsWith('body--')) {
      body.classList.remove(classString);
    }
  });
  if (newBodyClass) {
    body.classList.add(`body--${newBodyClass}`);
  }
}

function ajax(options) {
  const { url, pushState, popState = false } = options;
  // The 'page-is-loading' class means the loader covers the whole of the page - we use this on initial page load (when fonts etc are still loading so we don't want to a FOUC).
  // We also use this class if the user has scrolled the page and then navigates to a different page, because...
  // If the user is at the TOP of the page, when ajax navigating we want to keep the header/nav visible, so we offset the top of the loader in the css.
  // body.classList.add(`${window.scrollY > 0 ? 'page-is-loading' : 'ajax-is-loading'}`);
  body.classList.add(`${window.scrollY > 0 || popState ? 'page-is-loading' : 'ajax-is-loading'}`);

  axios.get(url).then((res) => {
    // Replace HTML
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res.data, "text/html");
    const content = resHTML.querySelector('.js-ajax-content');
    const container = document.querySelector('#ajax-container');
    const newBodyClass = content.dataset.class;
    container.innerHTML = '';
    container.append(content);
    // Check for script tag
    const scriptContainer = content.querySelector('.js-ajax-content-script');
    if (!scriptContainer) {
      // Update history if pushState is true
      if (pushState) {
        updateHistory(url);
      }
      // Removes page-is-loading and ajax-is-loading classes, and animates in content
      load();
      // Update body modifier class
      updateBodyModifier(newBodyClass);
      return;
    }
    const script = scriptContainer.firstElementChild;
    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.src = script.src;
    newScript.onload = () => {
      console.log('script loaded');
      if (pushState) {
        updateHistory(url);
      }
      load();
      updateBodyModifier(newBodyClass);
    }
    container.append(newScript);
  })
}

function handlePopState(e) {
  if (!e.state && typeof event.state !== 'string') {
    return;
  }
  e.preventDefault();
  ajax({
    url: e.state,
    pushState: false,
    popState: true
  });
}

export default function() {
  history.scrollRestoration = 'manual';
  // Store an initial state, so the back button works properly when returning
  // to the page you started on:
  history.replaceState(window.location.href, null, window.location.href);
  window.addEventListener('popstate', handlePopState);

  document.addEventListener('click', function(e) {
    const clicked = e.target;
    const link = clicked.closest('a');
    // Bit of a hacky way of checking for internal links, probs better to give them all a class instead
    if (link && link.target !== '_blank') {
      // Always close mobile nav when navigating within site
      e.stopPropagation();
      e.preventDefault();
      nav.hideMobileNav();
      ajax({
        url: link.href,
        pushState: true
      });
    }
  });
}
