import gsap from 'gsap';

const btn = document.querySelector('#mobile-nav-btn');
const navEl = document.querySelector('#nav');

const hideMobileNav = () => {
  navEl.classList.remove('mobile-nav-is-active');
  btn.classList.remove('nav-btn-is-active');
  gsap.set(navEl, {clearProps: 'all'});
}

function showMobileNav() {
  navEl.classList.add('mobile-nav-is-active');
  btn.classList.add('nav-btn-is-active');
  const height = `${navEl.scrollHeight}px`;
  gsap.fromTo(navEl, {
    opacity: 0,
    height: 0,
  }, {
    opacity: 1,
    height: height,
    duration: 0.2,
    ease: 'back.out(1.7)'
  })
}

function toggleMobileNav() {
  if (!navEl.classList.contains('mobile-nav-is-active')) {
    showMobileNav();
  } else {
    hideMobileNav();
  }
}

const nav = {
  hideMobileNav: hideMobileNav,
  init() {
    btn.addEventListener('click', toggleMobileNav);
  }
}

export default nav;
