import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function() {
  document.body.classList.remove('page-is-loading', 'ajax-is-loading');
  const items = Array.from(document.querySelectorAll('.js-animate-in, .js-animate-in-children > *'));
  if (!items.length) {
    return;
  }
  // Reset window scroll position
  window.scroll(0, 0);
  ScrollTrigger.batch(items, {
    onEnter: (els) => {
      gsap.to(els, {
        y: 0,
        opacity: 1,
        duration: 0.2,
        stagger: 0.05,
        ease: 'back.out(1.7)'
      });
    },
    once: true,
  });
}
